.button {
  width: 95%;
  background: var(--theme-btn-bg);
  color: var(--theme-page-text);
  padding: 15px 30px;
  border: none;
  border-radius: .3rem;
  cursor: pointer;
  font-size: 1em;
  border: 2px solid var(--theme-btn-bg);
}

.button.primary {
  color: var(--theme-btn-more-txt);
}

.button.secondary {
  background: transparent;
  color: var(--theme-btn-less-txt);
}

.button:disabled {
  background-color: var(--theme-btn-disabled);
  cursor: not-allowed;
  border-color: var(--theme-btn-disabled-border);
  color: var(--theme-btn-disabled-color);
}

.button.secondary:disabled {
  color: var(--theme-btn-disabled-color);
}