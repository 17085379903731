.text-input {
  width: 100%;
  font-size: 1.2em;
  padding: 15px 25px;
  border-radius: .5em;
  border: 1px solid var(--theme-input-border);
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  background-color: var(--theme-input-bg);
}

textarea:focus, input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.text-input:focus {
  border: 1px solid #5d5d5d;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}